<template>
  <div :class="{ ' MainSection': true, 'MainSection-left': id % 2 === 1 }">
    <div :id="id" class="SubSection">
      <div :id="id" class="SubSectionTxt">
        <h3 :id="id">{{ AdventureTitle }}</h3>
        <p :id="id">{{ Adventurecription }}</p>
        <div @click="scrollTo('navigation_bar')" class="viewMorebtn">
          <router-link to="/rooms"><button>Book Now</button></router-link>
        </div>
      </div>
    </div>
    <div :id="id" class="SubSection">
      <img :id="id" :src="image" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailsServices",
  props: {
    id: Number,
    AdventureTitle: String,
    Adventurecription: String,
    image: String,
  },
  methods: {
    scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>

<style scoped>
.MainSection {
  color: #2e3c8b;
  margin: auto;
  width: 90%;
  display: flex;
  /* height: 500px; */
}
.MainSection-left {
  flex-direction: row-reverse;
}
.SubSection {
  display: grid;
  place-items: center;
  width: 100%;
  /* margin: 1%; */
  background-color: #fafafa;
  line-height: 30px;
}
.SubSectionTxt {
  padding: 50px;
  /* background-color: red; */
}
.SubSection h3 {
  margin-bottom: 8%;
  color: #006233;
  /* font-family: Courgette; */
  font-size: 40px;
  line-height: 50px;
}
.SubSection p {
  /* padding-left: 50px;
  padding-right: 50px; */
  color: #333;
  font-family: Raleway;
  font-size: 28px;
  line-height: 28px;
}
.SubSection img {
  width: inherit;
  height: inherit;
}
button {
  height: 60px;
  width: 197.52px;
  border-radius: 8px;
  border: 2px solid #006233;
  color: #006233;
  /* font-family: Courgette; */
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  background-color: transparent;
}
@media screen and (max-width: 720px) {
  .MainSection {
    display: block;
  }
  .SubSection p {
    padding-left: unset;
    padding-right: unset;
    text-align: center;
    font-size: 20px;
  }
  .SubSectionTxt {
    padding: 20px;
    display: grid;
    place-items: center;
    margin-top: 50px;
  }
  .SubSection h3 {
    font-size: 30px;
  }
}
</style>