<template>
  <div id="Hero" :style="backgroundStyle">
    <div class="HeaderSection" :style="HeaderSection">
      <div class="darklayer" >
        <h1 :style="h1Style">{{ header }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
  components: {},
  props: {
    header: String,
    h1Style: String,
    HeaderSection: String,
    backgroundStyle: Array[""],
  },
  methods: {
    navigate() {
      this.$emit("navigate");
    },
  },
};
</script>

<style scoped>
#Hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
  contain: content;
  display: grid;
  align-items: center;
}
.HeaderSection {
  /* background-color: rgba(200,200,200,0.59); */
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  padding: 200px auto 300px auto;
}
.darklayer {
  color: #fff;
}
h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 68px;
  text-align: center;
}
@media screen and (max-width: 720px) {
  #Hero {
    background-repeat: no-repeat;
    background-position: top;
    height: 100vh;
    contain: unset;
    display: grid;
    align-items: center;
  }
  h1 {
    font-size: 50px;
  }
}
</style>''