<template>
  <div>
    <Header />
    <Hero
      :header="adventure.header"
      :h1Style="adventure.h1Style"
      :backgroundStyle="adventure.backgroundStyle"
    />
    <div class="adve">
      <AdventureDetails
        v-for="service in services"
        :key="service.id"
        :id="service.id"
        :AdventureTitle="service.AdventureTitle"
        :Adventurecription="service.Adventurecription"
        :image="service.AdventureImage"
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import AdventureDetails from "../components/Adventure/AdventureDetails";
import Hero from "../components/Hero";
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
export default {
  components: {
    Header,
    AdventureDetails,
    Hero,
    Footer,
  },
  data() {
    return {
      adventure: {
        header: "Adventure Awaits",
        backgroundStyle: {
          backgroundImage: `url(${require("../assets/Adventure_Images/adventure-bg.png")})`,
        },
        h1Style: {
          color: "#fff",
          fontize: "80px",
        },
      },

      header: "Checkups",
      description:
        "We offer a range of Checkups to help our community determine a range of different metrics with regards to their health.",

      services: [
        {
          id: 1,
          AdventureTitle: "Safari",
          Adventurecription:
            "Serengeti National Park is a World Heritage Site teeming with wildlife: over 2 million ungulates, 4000 lions, 1000 leopard, 550 cheetahs and some 500 bird species. Join us on a safari and explore the endless Serengeti.",
          AdventureImage: require("../assets/Adventure_Images/Safari.jpg"),
        },
        {
          id: 2,
          AdventureTitle: "Mount Climbing",
          Adventurecription:
            "Tanzania is often known for being home to Mount Kilimanjaro, a must-do for many visitors. It is also home to Mount Meru and picturesque waterfalls which make  a great day trip whilst in Arusha.",
          AdventureImage: require("../assets/Adventure_Images/MountainClimbing.jpg"),
        },
        {
          id: 3,
          AdventureTitle: "Zanzibar",
          Adventurecription:
            "A great destination to visit, the Beaches are lovely and Stonetown is a great town to stroll around in and the fish market at night is awesome.",
          AdventureImage: require("../assets/Adventure_Images/Zanzibar.jpg"),
        },
        {
          id: 4,
          AdventureTitle: "Other Excursions",
          Adventurecription:
            "We have quite the list for things to do as you stay with us. You can visit the Masai Villages, Lake Duluti, Marangu Waterfalls, Kurikye Caves also well known as the Hot Springs, as well as Chemka. We will assist you in making the arrangements.",
          AdventureImage: require("../assets/Adventure_Images/OtherExcursion.jpg"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.adve {
  margin-bottom: 50px;
}
</style>